<template>
  <div style="min-height: calc(100vh - 20px)" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh">
            <b-col class="d-flex justify-content-center align-items-center">
              <div v-if="!isReqLoding" >
                <img
                  v-show="BrandSetting?.image?.logo"
                  :src="BrandSetting?.image?.logo"
                  width="100px"
                  alt="logo"
                />
                <img
                  v-show="BrandSetting?.image?.text_logo"
                  :src="BrandSetting?.image?.text_logo"
                  width="250px"
                  alt="text_logo"
                />
                <p class="text-center mt-3">
                  <b v-if="verifyType == 'success'">{{ $t("account.verify_email.verify_success") }}</b>
                  <b v-else-if="verifyType == 'verifyed'">{{ $t("account.verify_email.verify_already") }}</b>
                  <b v-else>{{ $t("account.verify_email.verify_fail") }}</b>
                </p>
                <p v-if="isSuccess" class="text-center">
                  {{ $t("account.verify_email.verify_success_thank") }}
                </p>
                <p v-else class="text-center">
                  {{ $t("account.verify_email.verify_fail_login") }}
                </p>
                <!-- <p v-if="isSuccess" class="text-center">SMS2PRO</p> -->
                <hr>
                <div class="text-center my-4">
                  <b-button class="button-wizard px-5"
                    variant="primary"
                    @click="signin()"
                  >
                    {{ isSuccess? $t("account.verify_email.verify_success_signin") : $t("account.verify_email.verify_signin") }} 
                    ( {{ timerShower }} )
                  </b-button>
                </div>
              </div>
              <div v-else class="my-5 text-center">
                <b-spinner style="width: 1.5rem; height: 1.5rem" class="align-middle mr-2"/>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Language from "@/components/common/Language.vue";
import profileApi from "@/repository/profileApi";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "VerifyEmailLink",
  components: {
    Language,
  },
  data() {
    return {
      isReqLoding: false,
      isSuccess: false,
      verifyType: 'success',
      token: '',
      email: '',
      errorEmail: "",
      timeRemaining: 10,
      intervalId: null,
      timerShower: this.formatTime(10),
    };
  },
  computed: {
    ...mapState({}),
    BrandSetting() {
      return this.$store.getters["profile/getBandSetting"];
    },
  },
  created() {
    this.$i18n.locale = this.$route.query.locale ? this.$route.query.locale : localStorage.getItem("lang");
    this.checkVerifyEmail();
  },
  methods: {
    startTimer() {
      this.timerShower = this.formatTime(this.timeRemaining);
      this.intervalId = setInterval(() => {
        this.timeRemaining--;
        if (this.timeRemaining <= 0) {
          clearInterval(this.intervalId);
          this.$router.push('Login')
        }
        this.timerShower = this.formatTime(this.timeRemaining);
      }, 1000);
    },
    formatTime(seconds) {
      const remainingSeconds = seconds % 60;
      return `${String(remainingSeconds)}S`;
    },
    checkVerifyEmail() {
      this.isReqLoding = true;
      const token = this.$route.query.token
      const email = this.$route.query.email == '' ||  this.$route.query.email == null ? localStorage.getItem('email') : this.$route.query.email
      const lower = email == '' || email == null ? email : email.toLowerCase()
      const body = {
        token: token,
        email: lower
      };
      profileApi.checkVerifyEmailOTP(body).then((response) => {  
        if (response.data.codeSYS === "001") {
          this.verifyType = 'success'
          this.isSuccess = true
        }
        else if (response.data.codeSYS === "428") {
          this.verifyType = 'verifyed'
          this.isSuccess = true
        } else {
          this.verifyType = 'fail'
          this.isSuccess = false
        }
      })
      .catch((err) => {
        this.verifyType = 'fail'
        this.isSuccess = false
      })
      .finally(() => {
        this.startTimer();
        this.isReqLoding = false;
      });
    },
    signin() {
      this.$router.push('Login').catch(() => {})
    },
  },
};
</script>
<style scoped>
.polycy-link {
  color: var(--primary-color);
  cursor: pointer;
}

.wizard-tab-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#otpInput::v-deep {
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    justify-content: space-around;
  }
}

#otpInput::v-deep .otp-input-register {
  width: 5.5rem;
  height: 5.5rem;
  padding: 5px;
  margin: 1rem 0 0 0;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #e9e9e9;
  font-size: 36px;

  &.error {
    border: 1px solid red !important;
  }

  @media (max-width: 576px) {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: 10px;
    font-size: 25px;
  }
}

#otpInput::v-deep .otp-input-register-error {
  border: 1px solid #e9e9e9;
  background: #ffe9e9;
  color: red;
  width: 5.5rem;
  height: 5.5rem;
  padding: 5px;
  margin: 1rem 0 0 0;
  text-align: center;
  border-radius: 20px;
  font-size: 36px;
  @media (max-width: 576px) {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
    border-radius: 10px;
    font-size: 25px;
  }
}

.edit-txt {
  cursor: pointer;
  color: #19cbac;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 250% */
}
</style>
